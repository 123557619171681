export default (data = {}) => {
    return {
        public_key: null,
        currency: null,
        amount_in_cents: null,
        reference: null,
        email: null,
        full_name: null,
        phone_number: null,
        address_line_1: null,
        country: null,
        city: null,
        region: null,
        redirect_url: null,
        ...data
    }
}

