export default (data = {}) => {
    return {
        online: false,
        payments: false,
        booking: false,
        categories: true,
        whitPhoto: true,
        font: "Roboto",
        bgcolor: "white",
        categories_order: "",
        stripePK:null,
        epaycoPK:null,
        epayco_test: true,
        wompiPK: null,
        ...data
    }
}