var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"top_m",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":""}},[(this.pdfdata.header.doc_code)?_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-md-left text-center",attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.pdfdata.store.name))]),_vm._v(" "+_vm._s(_vm.pdfdata.store.address)),_c('br'),_vm._v(" "+_vm._s(_vm.pdfdata.store.city + ", " + _vm.pdfdata.store.province)),_c('br'),_vm._v(" "+_vm._s(_vm.pdfdata.store.mobile)),_c('br'),_c('span',{staticClass:"totals"},[_vm._v("Orden No. "+_vm._s(_vm.pdfdata.header.doc_code))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.pdfdata.header.party_name))]),_vm._v(" "+_vm._s(_vm.pdfdata.header.party_address)),_c('br'),_vm._v(" "+_vm._s(_vm.pdfdata.header.party_city + ", " + _vm.pdfdata.header.party_province)),_c('br'),_vm._v(" "+_vm._s(_vm.pdfdata.store.mobile)),_c('br'),_vm._v(" "+_vm._s(_vm.pdfdata.header.party_email)),_c('br'),_vm._v(" Para entregar : "+_vm._s(_vm.pdfdata.header.delivery_date + " @" + _vm.pdfdata.header.delivery_time)+" ")])],1),_c('v-data-table',{staticClass:"table-cursor elevation-1",attrs:{"calculate-widths":"","headers":_vm.header[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.pdfdata.items,"items-per-page":-1,"item-key":"index","hide-default-footer":"","sort-by":"name","mobile-breakpoint":"0","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('b',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.product_name))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(parseFloat(item.size).toLocaleString("es") + " " + item.uom)+" "),_c('br'),(item.shipping_time > 1)?_c('small',[_vm._v(" Tiempo de entrega: "+_vm._s(item.shipping_time)+" días hábiles. ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.note)}})])]}},{key:"item.pack",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((parseFloat(item.pack) * item.size).toLocaleString(2) + " " + item.uom)+" ")]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.qty))+" ")]}},{key:"item.tax_base",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + ( parseFloat(item.tax_base) * parseFloat(item.qty) * parseFloat(item.pack) ).toLocaleString())+" ")]}},{key:"item.tax_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + ( parseFloat(item.tax_value) * parseFloat(item.qty) * parseFloat(item.pack) ).toLocaleString())+" ")]}},{key:"item.tItem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$" + parseFloat(item.tItem))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v("Totales")]):_vm._e()]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.totals.qty.toLocaleString())+" ")]),(!_vm.$vuetify.breakpoint.xsOnly)?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.totals.subtotal.toLocaleString())+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.totals.tax.toLocaleString())+" ")]):_vm._e(),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.totals.total.toLocaleString())+" ")])])])],2),(_vm.paid)?_c('v-data-table',{staticClass:"table-cursor mt-3",attrs:{"headers":_vm.header_payments,"items":_vm.pdfdata.payments,"items-per-page":-1,"item-key":"code","hide-default-footer":"","sort-by":"name","mobile-breakpoint":"0","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.payment_type))]),_c('br'),_c('small',[_vm._v(_vm._s(item.issue_date))]),_c('br'),_c('small',[_vm._v(_vm._s(item.payment_reference))])]}},{key:"item.payment_value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(parseFloat(item.payment_value).toLocaleString())+" ")])]}}],null,true)}):_vm._e()],1)],1):_c('v-alert',{attrs:{"border":"right"},model:{value:(_vm.notResponse),callback:function ($$v) {_vm.notResponse=$$v},expression:"notResponse"}},[_vm._v(" Documento no exite ..."),_c('br'),_c('v-btn',{attrs:{"text":"","to":"/"}},[_vm._v("Regresar")])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","max-width":"480"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando ... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),(_vm.paid)?_c('v-bottom-navigation',{staticClass:"elevation-0",attrs:{"app":_vm.$vuetify.breakpoint.xsOnly,"grow":""}},[_c('v-btn',{attrs:{"to":"MyOrders"}},[_vm._v("Regresar")]),_c('v-spacer')],1):_vm._e(),(!_vm.paid)?_c('v-bottom-navigation',{staticClass:"elevation-0",attrs:{"app":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-btn',{attrs:{"dark":"","to":"/MyOrders"}},[_vm._v(" Regresar ")]),_c('v-btn',{on:{"click":function($event){return _vm.print_pdf()}}},[_vm._v("Descargar PDF")]),_c('v-btn',{on:{"click":_vm.checkout_wompi}},[_vm._v("Pagar Ahora")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }