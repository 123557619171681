export default (data = {}) => {
    return {
        docType: [
            {
              id: 1,
              group: "Caja",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              label: "Comprobante de Ingreso",
              value: "CIN",
              to: "Recibido de",
              concept: "Por concepto de:",
              paymode: "Forma de pago",
              credits: "2",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 2,
              group: "Caja",
              label: "Comprobante de Egreso",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "CEG",
              to: "Pagado a",
              concept: "Por concepto de:",
              paymode: "Pagado a",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por Cobrar",
                },
              ],
            },
            {
              id: 3,
              label: "Orden de Compra - Cliente",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "OR",
              to: "Cliente",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 4,
              label: "Remisión - Cliente",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "REC",
              to: "Destino",
              concept: "Productos / Servicios",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 5,
              label: "Orden de Compra - Proveedor",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "ORP",
              to: "Proveedor",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
            {
              id: 6,
              label: "Factura Electrónica",
              name: "Comprobante de Ingreso",
              description: "Gestión de pedidos de clientes",
              value: "FE",
              to: "Cliente",
              concept: "Producto / Servicio",
              paymode: "Forma de pago",
              fp: [
                {
                  codigo: "01",
                  valor: "Efectivo",
                },
                {
                  codigo: "02",
                  valor: "Transferencia",
                },
                {
                  codigo: "03",
                  valor: "Cuenta por pagar",
                },
              ],
            },
          ],
        ...data
    }
}
